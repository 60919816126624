import { post } from '@/utils/request';

// 获取台账数据
const getGoodsDataReq = (data) => post({
  url: '/store/statistical.StandingBook/goodsDataMaterial',
  data: {
    ...data,
  },
});

// 导出
const exportReq = (data) => post({
  url: '/store/statistical.StandingBook/dataMaterialDownload',
  responseType: 'blob',
  data: {
    ...data,
  },
});




export {
  getGoodsDataReq,
  exportReq,
};
